import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDLE, LOADING, SUCCEDED, FAILED} from '../shared/constants';
import { ApiError, AuthenticateService } from '../../api';

const initialState = {
  status: IDLE,
  error: null,
};

export const requestLogin = createAsyncThunk('login/fetch', async (data) => {
  try {
    const { email, password } = data;
    const response = await AuthenticateService.login(email, password);

    return response;
  } catch (e) {
    throw new ApiError(e);
  }
});

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: {
    [requestLogin.pending]: (state, action) => {
      state.status = LOADING;
    },
    [requestLogin.fulfilled]: (state, action) => {
      state.status = SUCCEDED;
      state.error = null;
    },
    [requestLogin.rejected]: (state, action) => {
      state.status = FAILED;
      state.error = action.error;
    }
  }
});

export const { clearError } = loginSlice.actions;

/*
* Selectors
* */
export const selectIsLoading= state => state.login.status === LOADING;
export const selectError = state => state.login.error;

export default loginSlice.reducer;
