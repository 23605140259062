const getToken = () => {
  return localStorage.getItem('token');
};

const getAuthUser = () => {
  return JSON.parse(localStorage.getItem('authUser'));
};

const clearLocalStorage = () => {
  localStorage.removeItem('authUser');
  localStorage.removeItem('token');
};

const setToken = (token) => {
  localStorage.setItem('token', token);
};

const setAuthUser = (data) => {
  localStorage.setItem('authUser', JSON.stringify(data));
};

export {
  getToken,
  setAuthUser,
  getAuthUser,
  clearLocalStorage,
  setToken
};
