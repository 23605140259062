import React from 'react';
import { func } from 'prop-types';
import { useSelector } from 'react-redux';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { selectIsLoggedIn } from '../auth/authSlice';
import logo from './logo_white.png';
import './NavBar.scss';

const NavBar = ({handleDrawerOpen}) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  if (!isLoggedIn) {
    return null;
  }

  const onMenuOpen = () => {
    handleDrawerOpen(true);
  };

  return (
    <AppBar position="sticky" className="custom-navbar">
      <Toolbar className="custom-navbar-container">
        <a href="https://myrodi.com">
          <img src={logo} className="logo"/>
        </a>
        <IconButton edge="end" color="inherit" aria-label="menu" onClick={onMenuOpen}>
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

NavBar.propTypes = {
  handleDrawerOpen: func.isRequired,
};

export default NavBar;
