import API from './api';

class Authenticate extends API {
  constructor(props) {
    super(props);
  }

  async login(username, pwd) {
    const payload = {
      usernameEmail: username,
      password: pwd,
    };

    const { data } = await this.post('/api/Authenticate/login', payload);

    return data;
  }

  async sendPasswordRecoveryEmail(email) {
    const payload = {
      email,
      resetPasswordUrl: 'http://localhost:3000/reset-password'
    };

    const { data } = await this.postNoAuth('/api/Authenticate/forgot', payload);

    return data;
  }

  async validatePasswordResetToken(email, resetToken) {
    const url = `/api/Authenticate/resetPasswordTokenCheck?email=${email}&resetToken=${resetToken}`;
    const { data } = await this.getNoAuth(url);

    return data;
  }

  async resetPasswordWithToken(email, resetToken, pwd) {
    const payload = {
      email,
      resetToken,
      password: pwd
    };
    const { data } = await this.postNoAuth('/api/Authenticate/resetPassword', payload);

    return data;
  }
}

const AuthenticateService = new Authenticate();

export default AuthenticateService;
