export default class ApiError {
  constructor(error) {
    if (error instanceof Error) {
      const { data = [], status = '', statusText = '' } = error.response;

      this.name = `${status} ${statusText}`;
      this.message = data[0].errorMessage;
    } else {
      this.name = error.name;
      this.message = error.message;
    }
  }
}
