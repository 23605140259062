import { makeStyles } from '@material-ui/core/styles';

const artistStyles = makeStyles((theme) => ({
	container: {
		padding: '0',
		width: '1',
		maxWidth: 'lg',
	},
	hero: {
		width: 1,
		height: '500px',
		maxHeight: { xs: '100px', md: '250px' },
	},
	heroText: {
		color: '#FFFFFF',
		mixBlendMode: 'difference',
		position: 'absolute',
		bottom: '40px',
		marginBottom: '16px',
		paddingLeft: '10px',
		left: 0,
		width: '100%',
	},
	heroActions: {
		position: 'absolute',
		bottom: 0,
		marginLeft: '10px',
		marginTop: '16px',
		background: '#000000',
		marginBottom: '16px',
		color: '#FFFFFF',
	},
	gigName: {
		fontFamily: 'Gotham',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '16px',
		color: '#00263E',
		flex: 'none',
		paddingBottom: '8px',
		// paddingTop: "8px",
	},
	gigDate: {
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '13px',
		color: '#00263E',
		paddingBottom: '8px',
	},
	gigContainer: {
		overflow: 'auto',
		height: '100%',
		display: 'flex',
		marginLeft: '32px',
		marginRight: '32px',
		width: '100%',
		background: '#00364D',
		alignItems: 'center',
		justifyContent: 'center',
	},
	gigSectionHeader: {
		color: '#000000',
		textAlign: 'center',
		width: '100%',
		overflowWrap: 'break-word',
		marginTop: '24px',
		marginBottom: '12px',
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '24px',
		lineHeight: '20px',
		letterSpacing: '0.01em',
		paddingTop: '48px',
	},
	gigSection: {
		background: '#00364D',
		paddingTop: '48px',
		paddingBottom: '48px',
	},
	gigCards: {
		justifyContent: 'center',
		alignItems: 'center',
	},
	socialSection: {
		paddingTop: '24px',
	},
	socialIcons: {
		marginRight: '12px',
	},
	sectionHeader: {
		textAlign: 'center',
		width: '100%',
		overflowWrap: 'break-word',
		marginTop: '24px',
		marginBottom: '12px',
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '24px',
		lineHeight: '20px',
		letterSpacing: '0.01em',
		color: '#FFFFFF',
	},
	socialDetails: {
		background: 'none',
		color: '#FFFFFF',
		borderRadius: '0',
		overflow: 'auto',
		height: '100%',
		display: 'flex',
	},
	socialItemContainer: {
		width: '160px',
		marginRight: '24px',
	},
	socialItem: {
		width: '160px',
		maxWidth: '180px',
		height: '52px',
		background: '#747474',
		borderRadius: '8px',
		'&:hover': { backgroundColor: '#adadad' },
	},
}));

export default artistStyles;
