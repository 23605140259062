import React from 'react';
import { object, func } from 'prop-types';
import {
  Card,
  IconButton,
  CardContent,
  CardActions,
  Grid,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import prettyBytes from 'pretty-bytes';

const DropzonePreview = ({ file, onClear }) => {
  const { name, size} = file;
  const fileName = `${name} (${prettyBytes(size)})`;
  const videoRef = React.createRef();

  React.useEffect(() => {
    if (file) {
      videoRef.current.src = URL.createObjectURL(file);
      videoRef.current.load()
    }
  }, [file]);

  return (
    <Card onClick={(e) => e.stopPropagation()}>
      <CardContent component="video" ref={videoRef} style={{ padding: 0 }} controls />
      <CardActions>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>{fileName}</Grid>
          <Grid item>
            <IconButton aria-label="clear" onClick={onClear}>
              <Clear />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

DropzonePreview.propTypes = {
  file: object.isRequired,
  onClear: func.isRequired,
};

export default DropzonePreview;
