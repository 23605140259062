import React from 'react';
import { string, func } from 'prop-types';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import './Alert.scss';

const Alert = ({message, onClose, type}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={`custom-snackbar ${type}`}
      open={true}
      autoHideDuration={null}
      message={message}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
};

Alert.propTypes = {
  message: string.isRequired,
  onClose: func.isRequired,
  type: string,
};

Alert.defaultProps = {
  type: ''
};

export default Alert;
