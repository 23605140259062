import axios from 'axios';
import { getToken } from '../auth';

class API {
	constructor(props = {}) {
		this.baseUrl = props.baseUrl || 'https://rodi-api.azurewebsites.net';
		this.endpoint = props.endpoint || '';
		this.headers = props.headers || {
			'Content-Type': 'application/json',
		};

		this.axiosInstance = axios.create({
			baseURL: this.baseUrl,
			headers: this.headers,
		});

		this.authInterceptor = this.axiosInstance.interceptors.request.use(
			(config) => {
				const token = getToken();

				if (token) {
					config.headers['Authorization'] = `Bearer ${token}`;
				} else {
					config.headers['xWebSession'] =
						'IWTBAGLwXygUGiHLHSzqUkziHBfMuEASMDMF';
				}

				return config;
			},
			(error) => {
				console.log('Error in API interceptor');
				Promise.reject(error);
			}
		);
	}

	async get(url) {
		return await this.axiosInstance.get(url);
	}

	async getNoAuth(url) {
		this.axiosInstance.interceptors.request.eject(this.authInterceptor);

		return await this.axiosInstance.get(url);
	}

	async post(url, payload) {
		return await this.axiosInstance.post(url, payload);
	}

	async postFormData(url, payload) {
		return await this.axiosInstance.post(url, payload, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	async postNoAuth(url, payload) {
		this.axiosInstance.interceptors.request.eject(this.authInterceptor);

		return await this.axiosInstance.post(url, payload);
	}

	getUrlWithQueryParams(url, queryParams) {
		const searchParams = new URLSearchParams(queryParams);

		return `${url}?${searchParams.toString()}`;
	}
}

export default API;
