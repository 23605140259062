import React from 'react';
import { bool, func } from 'prop-types';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar
} from '@material-ui/core';
import { Home, MusicVideo, AccountBox, ExitToApp } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { selectAuthUser } from '../auth/authSlice';
import './Menu.scss';

const ListItemLink = (props) => {
  return (
    <ListItem button component={RouterLink} {...props} onClick={props.handleCloseMenu}/>
  );
};

const Menu = ({ open, handleDrawerClose }) => {
  const authUser = useSelector(selectAuthUser);

  const onHandleClose = () => {
      handleDrawerClose(false);
  };

  const renderCurrentUser = () => {
    if (!authUser) return null;

    return (
      <div className="current-user">
        <Avatar alt={authUser.username} src={authUser.mainImageUrl}/>
        <Typography>{authUser.username}</Typography>
      </div>
    );
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onHandleClose}
      className="custom-drawer-menu"
    >
      { renderCurrentUser() }
      <Divider />
      <List>
        <ListItemLink to="/dashboard" handleCloseMenu={onHandleClose}>
          <ListItemIcon><Home/></ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemLink>
        <ListItemLink to="/bands" handleCloseMenu={onHandleClose}>
          <ListItemIcon><MusicVideo/></ListItemIcon>
          <ListItemText primary="Bands" />
        </ListItemLink>
        <ListItemLink to="/profile" handleCloseMenu={onHandleClose}>
          <ListItemIcon><AccountBox/></ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemLink>
      </List>
      <Divider />
      <List>
        <ListItemLink to="/logout" handleCloseMenu={onHandleClose}>
          <ListItemIcon><ExitToApp/></ListItemIcon>
          <ListItemText primary="Log out" />
        </ListItemLink>
      </List>
    </Drawer>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
  handleDrawerClose: func.isRequired,
};

export default Menu;
