import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDLE, LOADING, SUCCEDED, FAILED } from '../shared/constants';
import { BandsService } from '../../api';

const initialState = {
	status: IDLE,
	error: null,
	artistList: [],
	artistProfile: null,
	artistMembers: [],
	artistGigs: [],
};

export const requestAllArtists = createAsyncThunk(
	'bands/all/fetch',
	async () => {
		const response = await BandsService.all();

		return response;
	}
);

export const requestArtistProfile = createAsyncThunk(
	'band/profile/fetch',
	async (id) => {
		const response = await BandsService.getProfile(id);

		return response;
	}
);

export const requestArtistMembers = createAsyncThunk(
	'band/members/fetch',
	async (id) => {
		const response = await BandsService.getMembers(id);

		return response;
	}
);

export const requestArtistGigs = createAsyncThunk(
	'gigs/band/fetch',
	async (id) => {
		const response = await BandsService.getGigs(id);

		return response;
	}
);

export const requestCustomKeyArtistProfile = createAsyncThunk(
	`band/profileFromKey/fetch`,
	async (key) => {
		const response = await BandsService.getArtistByKey(key);

		return response;
	}
);

export const requestCustomKeyArtistGigs = createAsyncThunk(
	`gigs/band/key/fetch`,
	async (key) => {
		const response = await BandsService.getArtistGigsByKey(key);

		return response;
	}
);

export const artistSlice = createSlice({
	name: 'artist',
	initialState,
	extraReducers: {
		[requestAllArtists.pending]: (state, action) => {
			state.status = LOADING;
			state.artistProfile = initialState.artistProfile;
			state.artistMembers = initialState.artistMembers;
		},
		[requestAllArtists.fulfilled]: (state, action) => {
			state.status = SUCCEDED;
			state.error = null;
			state.artistList = action.payload;
		},
		[requestAllArtists.rejected]: (state, action) => {
			state.status = FAILED;
			state.error = action.error;
		},
		[requestArtistProfile.pending]: (state, action) => {
			state.status = LOADING;
		},
		[requestArtistProfile.fulfilled]: (state, action) => {
			state.status = SUCCEDED;
			state.error = null;
			state.artistProfile = action.payload;
		},
		[requestArtistProfile.rejected]: (state, action) => {
			state.status = FAILED;
			state.error = action.error;
		},
		[requestArtistMembers.pending]: (state, action) => {
			state.status = LOADING;
		},
		[requestArtistMembers.fulfilled]: (state, action) => {
			state.status = SUCCEDED;
			state.error = null;
			state.artistMembers = action.payload;
		},
		[requestArtistMembers.rejected]: (state, action) => {
			state.status = FAILED;
			state.error = action.error;
		},
		[requestArtistGigs.pending]: (state, action) => {
			state.status = LOADING;
		},
		[requestArtistGigs.fulfilled]: (state, action) => {
			state.status = SUCCEDED;
			state.error = null;
			state.artistGigs = action.payload;
		},
		[requestArtistGigs.rejected]: (state, action) => {
			state.status = FAILED;
			state.error = action.error;
		},
		[requestCustomKeyArtistProfile.pending]: (state, action) => {
			state.status = LOADING;
		},
		[requestCustomKeyArtistProfile.fulfilled]: (state, action) => {
			state.status = SUCCEDED;
			state.error = null;
			state.artistProfile = action.payload;
		},
		[requestCustomKeyArtistProfile.rejected]: (state, action) => {
			state.status = FAILED;
			state.error = action.error;
		},
		[requestCustomKeyArtistGigs.pending]: (state, action) => {
			state.status = LOADING;
		},
		[requestCustomKeyArtistGigs.fulfilled]: (state, action) => {
			state.status = SUCCEDED;
			state.error = null;
			state.artistGigs = action.payload;
		},
		[requestCustomKeyArtistGigs.rejected]: (state, action) => {
			state.status = FAILED;
			state.error = action.error;
		},
	},
});

/*
 * Selectors
 * */
export const selectIsLoading = (state) => state.artist.status === LOADING;
export const selectError = (state) => state.artist.error;
export const selectArtist = (state) => state.artist.artistList;
export const selectArtistProfile = (state) => state.artist.artistProfile;
export const selectArtistMembers = (state) => state.artist.artistMembers;
export const selectArtistGigs = (state) => state.artist.artistGigs;

export default artistSlice.reducer;
