import loginReducer from '../features/login/loginSlice';
import forgotPasswordReducer from '../features/forgot-password/forgotPasswordSlice';
import authReducer from '../features/auth/authSlice';
import userReducer from '../features/profile/userSlice';
import bandsReducer from '../features/bands/bandsSlice';
import artistReducer from '../features/artist-page/artistSlice'
import uploadVideoReducer from '../features/upload-video/uploadVideoSlice';

const reducers = {
  login: loginReducer,
  forgotPassword: forgotPasswordReducer,
  auth: authReducer,
  user: userReducer,
  bands: bandsReducer,
  artist: artistReducer,
  uploadVideo: uploadVideoReducer,
};

export default reducers;
