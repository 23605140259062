import { createSlice } from '@reduxjs/toolkit';
import { setToken, setAuthUser, getAuthUser, clearLocalStorage } from '../../auth';

const initialState = {
  error: null,
  user: getAuthUser(),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      const { token, ...rest } = action.payload;
      state.user = rest;
      setToken(token);
      setAuthUser(rest);
    },
    logout: (state) => {
      clearLocalStorage();
      state.user = null;
      state.error = null;
    },
  }
});

export const { setAuth, logout } = authSlice.actions;

/*
* Selectors
* */
export const selectIsLoggedIn = state => !!state.auth.user;
export const selectError = state => state.auth.error;
export const selectAuthUser = state => state.auth.user;

export default authSlice.reducer;
