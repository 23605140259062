import API from './api';

class Bands extends API {
	constructor(props) {
		super(props);
	}

	async all() {
		const { data } = await this.get('/api/bands/all');

		return data;
	}

	async getProfile(id) {
		const { data } = await this.get(`/api/bands/${id}`);

		return data;
	}

	async getMembers(id) {
		const { data } = await this.get(`/api/bandmembers/band/${id}`);

		return data;
	}

	async getGigs(id) {
		const { data } = await this.get(`/api/gigs/band/${id}`);

		return data;
	}

	async getArtistByKey(key) {
		const { data } = await this.get(`/api/bands/key?uniqueKey=${key}`);

		return data;
	}

	async getArtistGigsByKey(key) {
		const { data } = await this.get(`/api/gigs/band?uniqueKey=${key}`);

		return data;
	}
}

const BandsService = new Bands();

export default BandsService;
