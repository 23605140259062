import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAuthUser, setToken } from '../../auth';
import { authSlice } from '../auth/authSlice';
import { IDLE, LOADING, SUCCEDED, FAILED} from '../shared/constants';
import { UserService } from '../../api';

const initialState = {
  status: IDLE,
  error: null,
  profile: null
};

export const requestProfile = createAsyncThunk('user/profile', async () => {
  const response = await UserService.me();

  return response;
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
  extraReducers: {
    [requestProfile.pending]: (state, action) => {
      state.status = LOADING;
    },
    [requestProfile.fulfilled]: (state, action) => {
      state.status = SUCCEDED;
      state.error = null;
      state.profile = action.payload;
    },
    [requestProfile.rejected]: (state, action) => {
      state.status = FAILED;
      state.error = action.error;
    }
  }
});

export const { setProfile } = userSlice.actions;

/*
* Selectors
* */
export const selectIsLoading= state => state.user.status === LOADING;
export const selectError = state => state.user.error;
export const selectUserProfile = state => state.user.profile;

export default userSlice.reducer;
