import React from 'react';
import * as yup from 'yup';

const SUPPORTED_IMG_FORMATS = ['image/jpg', 'image/png', 'image/jpeg'];
const SUPPORTED_VID_FORMATS = ['video/mp4', 'video/mpeg', 'video/mpg', 'video/mov'];

export const videoInformationSchema = yup.object().shape({
  videoName: yup
    .string('Enter the name of the video')
    .required('The video name is required'),
  youTubeUrl: yup
    .string('Enter a YouTube URL for the video')
    .url('Enter a valid YouTube URL for the video')
    .required('The YouTube URL is required'),
  mediaUsageType: yup
    .string('Select the Media Usage Type')
    .required('The Media Usage Type is required'),
  description: yup
    .string('Enter the description of the video')
    .required('The video description is required')
});

export const uploadVideoSchema = yup.object().shape({
  thumbnail: yup
    .mixed()
    .test('required', 'The Video Thumbnail is required', (value) => value && value.length > 0)
    .test('fileType', 'Unsupported image format', (value) => {
      return value && value.length > 0 && SUPPORTED_IMG_FORMATS.includes(value[0].type);
    }),
  video: yup
    .mixed()
    .test('required', 'The Video File is required', (value) => value && value.length > 0)
    .test('fileType', 'Unsupported video format', (value) => {
      return value && value.length > 0 && SUPPORTED_VID_FORMATS.includes(value[0].type);
    }),
});

export const useCustomValidation = async (data) => {
  try {
    let values = {};
    let opts = { abortEarly: false };

    if (data.hasOwnProperty('videoName')) {
      values = await videoInformationSchema.validate(data, opts);
    } else if (data.hasOwnProperty('thumbnail')) {
      values = await uploadVideoSchema.validate(data, opts);
    }

    return {
      values,
      errors: {}
    };
  } catch (errors) {
    return {
      values: {},
      errors: errors.inner.reduce(
        (allErrors, currentError) => ({
          ...allErrors,
          [currentError.path]: {
            type: currentError.type ?? "validation",
            message: currentError.message
          }
        }),
        {}
      )
    };
  }
};
