import React from 'react';
import { func, bool } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import {
  Button,
  FormHelperText,
  Grid,
  TextField
} from '@material-ui/core';
import { NavigateBefore, Publish } from '@material-ui/icons';
import Dropzone from './Dropzone';

const UploadVideoForm = (props) => {
  const { register, errors } = useFormContext();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          type="file"
          id="thumbnail"
          name="thumbnail"
          disabled={props.isLoading}
          inputRef={register}
          label="Video Thumbnail"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            accept: "image/*"
          }}
          error={!!errors.thumbnail}
          helperText={!!errors.thumbnail && errors.thumbnail.message}
          fullWidth />
      </Grid>
      <Grid item>
        <Dropzone
          loading={props.isLoading}
          error={!!errors.video} />
        { !!errors.video && <FormHelperText error>{errors.video.message}</FormHelperText> }
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs>
          <Button
            variant="contained"
            color="default"
            onClick={props.onPreviousStep}
            disabled={props.isLoading}
            startIcon={<NavigateBefore>back</NavigateBefore>}
            fullWidth >
            back
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            variant="contained"
            disabled={props.isLoading}
            color="primary"
            type="submit"
            endIcon={<Publish>submit</Publish>}
            fullWidth >
            submit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

UploadVideoForm.propTypes = {
  onPreviousStep: func.isRequired,
  isLoading: bool
};

UploadVideoForm.defaultProps = {
  isLoading: false
};

export default UploadVideoForm;
