import API from './api';

class Upload extends API {
  constructor(props) {
    super(props);
  }

  async uploadVideo(formData) {
    const { data } = await this.postFormData('/api/upload/video', formData);

    return data;
  }
}

const UploadService = new Upload();

export default UploadService;
