import React from 'react';
import { bool, func } from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  TextField,
  Grid,
  Link,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl, FormHelperText
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import loginSchema from './validationSchema';

import './LoginForm.scss';

const LinkRouter = (props) => <Link {...props} component={RouterLink}/>;

const LoginForm = (props) => {
  const { isLoading, handleOnSubmit } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(loginSchema)
  });

  const onSubmit = async (data) => {
    try {
      await handleOnSubmit(data);
    } catch (e) {
      throw e;
    }
  };

  const toggleShowPassword = React.useCallback(() => setShowPassword(!showPassword), [showPassword, setShowPassword]);
  const renderHelperText = () => (
    <FormHelperText>{errors.password.message}</FormHelperText>
  );
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            variant="outlined"
            margin="normal"
            inputRef={register}
            id="email"
            label="Email Address"
            name="email"
            error={!!errors.email}
            helperText={!!errors.email && errors.email.message}
            disabled={isLoading}
            fullWidth
          />
        </Grid>
        <Grid item>
          <FormControl
            variant="outlined"
            error={!!errors.password}
            disabled={isLoading}
            fullWidth >
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              inputRef={register}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    disabled={isLoading}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={110}
            />
            { !!errors.password && renderHelperText() }
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            fullWidth
          >
            sign in
          </Button>
        </Grid>
        <Grid item container>
          <Grid item>
            <LinkRouter color="inherit" to="/forgot-password" variant="body2">
              Forgot password?
            </LinkRouter>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
    </form>
  );
};

LoginForm.propTypes = {
  handleOnSubmit: func.isRequired,
  isLoading: bool,
};

LoginForm.defaultProps = {
  isLoading: false
};

export default LoginForm;
