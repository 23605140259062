import React from 'react';
import Login from './features/login/Login';
import ForgotPassword from './features/forgot-password/ForgotPassword';
import ResetPassword from './features/forgot-password/ResetPassword';
import Logout from './features/logout/Logout';
import Home from './features/home/Home';
import NavBar from './features/navbar/NavBar';
import Dashboard from './features/dashboard/Dashboard';
import Bands from './features/bands/Bands';
import BandProfile from './features/bands/BandProfile';
import Profile from './features/profile/Profile';
import ManageBand from './features/manage-band/ManageBand';
import ConfirmEmail from './features/confirm-email/ConfirmEmail';
import QrLanding from './features/qr-landing/QRLanding';
import PrivacyPolicy from './features/privacy-policy/PrivacyPolicy';
import ArtistPage from './features/artist-page/ArtistPage';
import Menu from './features/navbar/Menu';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './features/auth/PrivateRoute';
import './App.scss';
import PageNotFound from './features/PageNotFound';

const App = () => {
	const [openDrawer, setOpenDrawer] = React.useState(false);

	return (
		<Router>
			<div className="App">
				<NavBar handleDrawerOpen={setOpenDrawer} />
				<Switch>
					{/* <Route path="/confirm-email" component={ConfirmEmail} />
					<Route path="/login" component={Login} />
					<Route path="/forgot-password" component={ForgotPassword} />
					<Route path="/reset-password" component={ResetPassword} />
					<Route path="/logout" component={Logout} />
					<Route path="/bcs" component={QrLanding} />
					<Route path="/privacy-policy" component={PrivacyPolicy} />
					<Route path="/artist/:artistId" component={ArtistPage} />
					<Route path="/a/:artistString" component={ArtistPage} />
					<PrivateRoute path="/dashboard" component={Dashboard} />
					<PrivateRoute path="/bands/:bandId/manage" component={ManageBand} />
					<PrivateRoute path="/bands/:bandId" component={BandProfile} />
					<PrivateRoute path="/bands" component={Bands} />
					<PrivateRoute path="/profile" component={Profile} />
					<Route exact path="/" component={Home} />
					<Route path="*" component={PageNotFound} /> */}
					<Route path="/privacy-policy" component={PrivacyPolicy} />
					<Route path="*" component={() => {
                        window.location.href = "https://launchrodi.com";
                        return null;
                    	}}/>
				</Switch>
				<Menu handleDrawerClose={setOpenDrawer} open={openDrawer} />
			</div>
		</Router>
	);
};

export default App;
