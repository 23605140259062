import { createSlice, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { IDLE, LOADING, SUCCEDED, FAILED} from '../shared/constants';
import { AuthenticateService, ApiError } from '../../api';

const initialState = {
  status: IDLE,
  error: null,
  email: null,
  resetToken: null,
  passwordResetComplete: false,
  recoveryEmailRequestComplete: false,
};

export const requestRecoveryEmail = createAsyncThunk('forgot-password/send-recovery-email', async (data) => {
  const { email } = data;
  const response = await AuthenticateService.sendPasswordRecoveryEmail(email);

  return response;
});

export const validatePasswordResetToken = createAsyncThunk('forgot-password/reset-password/validate-token', async (data, thunkApi) => {
  try {
    const { getState } = thunkApi;
    const { email, resetToken } = getState().forgotPassword;

    const response = await AuthenticateService.validatePasswordResetToken(email, resetToken);

    return response;
  } catch (e) {
    throw new ApiError(e);
  }
});

export const requestPasswordReset = createAsyncThunk(
  'forgot-password/reset-password',
  async (pwd, thunkApi) => {
    try {
      const { dispatch, getState } = thunkApi;
      const result = await dispatch(validatePasswordResetToken());

      await unwrapResult(result);

      const { email, resetToken } = getState().forgotPassword;
      const response = await AuthenticateService.resetPasswordWithToken(email, resetToken, pwd);

      return response;
    } catch (e) {
      throw new ApiError(e);
    }
  });

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setPasswordResetToken: (state, action) => {
      state.email = action.payload.email;
      state.resetToken = action.payload.resetToken;
    },
    clearError: (state) => {
      state.error = initialState.error;
      state.passwordResetComplete = initialState.passwordResetComplete;
      state.recoveryEmailRequestComplete = initialState.recoveryEmailRequestComplete;
    },
  },
  extraReducers: {
    [requestRecoveryEmail.pending]: (state) => {
      state.status = LOADING;
    },
    [requestRecoveryEmail.fulfilled]: (state) => {
      state.status = SUCCEDED;
      state.recoveryEmailRequestComplete = true;
      state.error = null;
    },
    [requestRecoveryEmail.rejected]: (state, action) => {
      state.status = FAILED;
      state.error = action.error;
    },
    [validatePasswordResetToken.pending]: (state) => {
      state.status = LOADING;
    },
    [validatePasswordResetToken.fulfilled]: (state) => {
      state.status = SUCCEDED;
      state.error = null;
    },
    [validatePasswordResetToken.rejected]: (state, action) => {
      state.status = FAILED;
      state.error = action.error;
    },
    [requestPasswordReset.pending]: (state) => {
      state.status = LOADING;
    },
    [requestPasswordReset.fulfilled]: (state) => {
      state.status = SUCCEDED;
      state.passwordResetComplete = true;
      state.error = null;
    },
    [requestPasswordReset.rejected]: (state, action) => {
      state.status = FAILED;
      state.error = action.error;
    }
  }
});

export const { setPasswordResetToken, clearError } = forgotPasswordSlice.actions;

/*
* Selectors
* */
export const selectIsLoading = state => state.forgotPassword.status === LOADING;
export const selectSucceeded = state => state.forgotPassword.status === SUCCEDED;
export const selectFailed = state => state.forgotPassword.status === FAILED;
export const selectPasswordResetComplete = state => state.forgotPassword.passwordResetComplete;
export const selectRequestRecoveryEmailComplete = state => state.forgotPassword.recoveryEmailRequestComplete;
export const selectError = state => state.forgotPassword.error;

export default forgotPasswordSlice.reducer;
