import React, { useCallback } from 'react'
import { bool } from 'prop-types';
import classnames from 'classnames';
import {
  Container,
  Box,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { Block, Movie, GetApp, CloudUpload, SyncProblem } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form';
import DropzonePreview from './DropzonePreview';
import styles from './Dropzone.module.scss';

const Dropzone = (props) => {
  const [ dropEvent, setDropEvent ] = React.useState(null);
  const { register, unregister, setValue, trigger } = useFormContext();
  const onDrop = useCallback(async (accepted, rejected, event) => {
    await setDropEvent(event);

    if (event && event.dataTransfer) {
      await setValue('video', event.dataTransfer.files);
      await trigger('video');
    }
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    inputRef,
    acceptedFiles,
  } = useDropzone({ accept: 'video/*', multiple: false, onDrop });

  const readyToUpload = acceptedFiles && acceptedFiles.length > 0;
  const renderPreview = !props.error && readyToUpload && !props.loading;

  React.useEffect(() => {
    register(inputRef.current);

    return () => {
      unregister(inputRef.current);
    };
  }, [register, unregister, inputRef]);

  const renderUploadElements = () => {
    const dropLabel = isDragActive ?
      !isDragReject ? 'Drop your video here' : 'File format not supported' :
      'Drag and drop your video file here';
    return (
      <Grid item>
        <Typography variant="h4" align="center">{dropLabel}</Typography>
        { !isDragActive && (<Typography variant="subtitle1" align="center">or click here to select the video file</Typography>) }
      </Grid>
    );
  };

  const getStatusIcon = () => {
    if (isDragReject && isDragActive) return 'block';
    if (isDragAccept && isDragActive) return 'getApp';
    if (!isDragActive && acceptedFiles.length === 0) return 'movie';
    if (props.error) return 'syncProblem';
  };

  const renderRoundIcon = () => {
    const iconProps = {
      style: {
        fontSize: 80
      }
    };

    const icons = {
      block: <Block {...iconProps}/>,
      getApp: <GetApp {...iconProps}/>,
      movie: <Movie {...iconProps}/>,
      cloudUpload: <CloudUpload {...iconProps}/>,
      syncProblem: <SyncProblem {...iconProps}/>
    };

    const statusIcon = getStatusIcon();

    if (!statusIcon) return null;

    return (
      <Grid item>
        {icons[statusIcon]}
      </Grid>
    );
  };

  const renderLoadingIndicator = () => {
    return (
      <>
        <Grid item>
          <CircularProgress />
        </Grid>
        <Grid item>
          <Typography variant="h4" align="center">Uploading your video...</Typography>
        </Grid>
      </>
    );
  };

  const containerClasses = classnames(styles.container, { [styles.error]: props.error });

  const handleOnClear = React.useCallback(async (e) => {
    e.stopPropagation();

    await getRootProps().onDrop(dropEvent);
    await setValue('video', '', { shouldValidate: false });

  }, [dropEvent, setValue]);

  return (
    <Container component="section" className={containerClasses} disableGutters>
      <Box p={2} {...getRootProps()}>
        <input {...getInputProps()} name="video"/>
        <Grid container direction="column" justify="center" alignItems="center">
          { renderPreview && <DropzonePreview file={acceptedFiles[0]} onClear={handleOnClear}/> }
          { !props.loading && renderRoundIcon() }
          { props.loading && renderLoadingIndicator() }
          { !readyToUpload && renderUploadElements() }
        </Grid>
      </Box>
    </Container>
  )
};

Dropzone.propTypes = {
  error: bool,
  loading: bool,
};

Dropzone.defaultProps = {
  error: false,
  loading: false
};

export default Dropzone;
