import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDLE, LOADING, SUCCEDED, FAILED } from '../shared/constants';
import { ApiError, UploadService } from '../../api';

const initialState = {
  status: IDLE,
  videoInformation: {},
  error: null,
};

export const uploadVideo = createAsyncThunk(
  'uploadVideo/post',
  async (formData) => {
    try {
      const response = await UploadService.uploadVideo(formData);

      return response;
    } catch (e) {
      throw new ApiError(e);
    }
  });

export const uploadVideoSlice = createSlice({
  name: 'uploadVideo',
  initialState,
  reducers: {
    setVideoInformation: (state, action) => {
      state.videoInformation = action.payload;
    },
    clearError: (state) => {
      state.error = null;
      state.status = IDLE;
    },
  },
  extraReducers: {
    [uploadVideo.pending]: (state, action) => {
      state.status = LOADING;
      state.error = null;
    },
    [uploadVideo.fulfilled]: (state, action) => {
      state.status = SUCCEDED;
      state.error = null;
    },
    [uploadVideo.rejected]: (state, action) => {
      state.status = FAILED;
      state.error = action.error;
    }
  }
});

export const { clearError, setVideoInformation } = uploadVideoSlice.actions;

/*
* Selectors
* */
export const selectStatus = state => state.uploadVideo.status;
export const selectVideoInformation = state => state.uploadVideo.videoInformation;
export const isLoading = state => state.uploadVideo.status === LOADING;
export const uploadSucceeded = state => state.uploadVideo.status === SUCCEDED;
export const selectError = state => state.uploadVideo.error;

export default uploadVideoSlice.reducer;
