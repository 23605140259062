import API from './api';

class User extends API {
  constructor(props) {
    super(props);
  }

  async me() {
    const { data } = await this.get('/api/Users/me');

    return data;
  }
}

const UserService = new User();

export default UserService;
