import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDLE, LOADING, SUCCEDED, FAILED} from '../shared/constants';
import { BandsService } from '../../api';

const initialState = {
  status: IDLE,
  error: null,
  bandsList: [],
  bandProfile: null,
  bandMembers: [],
};

export const requestAllBands = createAsyncThunk('bands/all/fetch', async () => {
  const response = await BandsService.all();

  return response;
});

export const requestBandProfile = createAsyncThunk('band/profile/fetch', async (id) => {
  const response = await BandsService.getProfile(id);

  return response;
});

export const requestBandMembers = createAsyncThunk('band/members/fetch', async (id) => {
  const response = await BandsService.getMembers(id);

  return response;
});

export const bandsSlice = createSlice({
  name: 'bands',
  initialState,
  extraReducers: {
    [requestAllBands.pending]: (state, action) => {
      state.status = LOADING;
      state.bandProfile = initialState.bandProfile;
      state.bandMembers = initialState.bandMembers;
    },
    [requestAllBands.fulfilled]: (state, action) => {
      state.status = SUCCEDED;
      state.error = null;
      state.bandsList = action.payload;
    },
    [requestAllBands.rejected]: (state, action) => {
      state.status = FAILED;
      state.error = action.error;
    },
    [requestBandProfile.pending]: (state, action) => {
      state.status = LOADING;
    },
    [requestBandProfile.fulfilled]: (state, action) => {
      state.status = SUCCEDED;
      state.error = null;
      state.bandProfile = action.payload;
    },
    [requestBandProfile.rejected]: (state, action) => {
      state.status = FAILED;
      state.error = action.error;
    },
    [requestBandMembers.pending]: (state, action) => {
      state.status = LOADING;
    },
    [requestBandMembers.fulfilled]: (state, action) => {
      state.status = SUCCEDED;
      state.error = null;
      state.bandMembers = action.payload;
    },
    [requestBandMembers.rejected]: (state, action) => {
      state.status = FAILED;
      state.error = action.error;
    }
  }
});

/*
* Selectors
* */
export const selectIsLoading= state => state.bands.status === LOADING;
export const selectError = state => state.bands.error;
export const selectBands = state => state.bands.bandsList;
export const selectBandProfile = state => state.bands.bandProfile;
export const selectBandMembers = state => state.bands.bandMembers;

export default bandsSlice.reducer;
