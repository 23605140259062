import React from 'react';
import { string, array, number, object, func } from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import GroupsIcon from '@material-ui/icons/Group';
import EventIcon from '@material-ui/icons/Event';
import Grow from '@material-ui/core/Grow';
import './BandCard.scss';
import SpotifyLogo from '../../img/spotify_icon_green.png';
import { Icon } from '@material-ui/core'

const SpotifyIcon = () => (
  <Icon>
      <img 
        src={SpotifyLogo}
        height={24}
        width={24}
        alt= "Spotify"
      /> 
  </Icon>
)

const BandCard = (props) => {
  const {
    bandName,
    image,
    followers,
    upcomingGigs,
    socialMedia,
    onClick,
    animationTimeout,
  } = props;

  const renderSocialMediaBtn = (social, index) => {
    const { url, network } = social;

    const checkUrl = (url) => (!/^https?:\/\//i.test(url)) ? 'https://' + url : url;
   
    return (
      <IconButton 
        color="primary" 
        onClick={()=> {
          window.open(checkUrl(url), '_blank');
        }}
      >
        { network.toLowerCase() === 'facebook' && (<FacebookIcon />) }
        { network.toLowerCase() === 'twitter' && (<TwitterIcon />) }
        { network.toLowerCase() === 'instagram' && (<InstagramIcon />) }
        { network.toLowerCase() === 'youtube' && (<YouTubeIcon />) }
        { network.toLowerCase() === 'linkedin' && (<LinkedInIcon />)}
        { network.toLowerCase() === 'spotify' && (<SpotifyIcon />)}
      </IconButton>
    );
  };

   const renderCardActions = () => {
    return (
      <CardActions classes={{ root: 'band-card-social-media' }}>
        <div className="band-card-details">
          <div className="band-card-details-followers">
            <GroupsIcon /> {followers}
          </div>
          <div className="band-card-details-upcoming-gigs">
            <EventIcon /> {upcomingGigs}
          </div>
        </div>
        <div className="band-card-social-media-buttons">
          { socialMedia.map(renderSocialMediaBtn) }
        </div>
      </CardActions>
    );
  };

  return (
    <Grow in timeout={animationTimeout}>
      <Card className="band-card">
        <CardActionArea onClick={onClick}>
          <CardMedia
            alt="Band"
            classes={{
              root: 'band-card-media'
            }}
            image={image.url}
            title={bandName}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {bandName}
            </Typography>
          </CardContent>
        </CardActionArea>
        { socialMedia && renderCardActions() }
      </Card>
    </Grow>
  );
};

BandCard.propTypes = {
  bandName: string.isRequired,
  image: object.isRequired,
  onClick: func,
  followers: number,
  upcomingGigs: number,
  socialMedia: array,
  animationTimeout: number
};

BandCard.defaultProps = {
  followers: 0,
  upcomingGigs: 0,
  socialMedia: [],
  animationTimeout: 0,
};

export default BandCard;
